import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import { checkUser } from "../redux/actions/user.actions";

const MainWrapper = styled.div`
  position: relative;
  color: white;
  display: block;
  vertical-align: top;
  background: #1b1b1b;
  width: calc(100% - 100px);
  min-height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin: 70px 0 0 100px;
  height: 100vh;
  z-index: 1;
`;

const SectionWrapper = styled.section`
  padding: 30px;
  max-width: 1200px;
  margin: auto;
`;

const Header = styled.h1`
  font-size: 40px;
  font-weight: 300;
  line-height: 1.5em;
`;

const SubHeader = styled.h3`
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 30px;
  line-height: 1.5em;
`;

const Content = styled.div`
  display: table;
  @media screen and (max-width: 770px) {
    display: block;
  }
  width: 100%;
  margin-top: 30px;
`;

const Features = styled.div`
  background: #2d2d2d;
  width: 50%;
  @media screen and (max-width: 770px) {
    width: 100%;
    display: block;
  }
  position: relative;
  display: table-cell;
  padding: 30px;
  vertical-align: top;
  text-align: left;

  & h3 {
    padding-bottom: 10px;
    font-weight: 600;
    margin-bottom: 30px;
    font-size: 24px;
  }

  & p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5em;
  }

  & p + p {
    padding-top: 15px;
  }

  & ul {
  }
`;

const MoreFeatures = styled.ul`
  padding: 30px 0;
  list-style: none;
  counter-reset: step;

  & li {
    background: #212121;
    color: #fff;
    position: relative;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5em;
    padding: 15px 15px 15px 65px;
  }

  & li:before {
    content: counter(step);
    counter-increment: step;
    color: #fff;
    background: #298eea;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: -16px;
    border-radius: 50%;
  }
`;

const PackageTime = styled.span`
  display: inline-block;
  padding-right: 10px;
`;

const PackagePrice = styled.span`
  display: inline-block;
  padding: 5px 10px 4px 10px;
`;

const Premiumform = styled.div`
  background: #212121;
  width: 50%;
  position: relative;
  display: table-cell;
  padding: 30px;
  vertical-align: top;
  text-align: left;

  & h3 {
    float: left;
    padding-bottom: 10px;
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 300;
  }
`;

const SecureServer = styled.div`
  float: right;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;

  & i {
    display: inline-block;
    color: #fff;
    vertical-align: text-bottom;
    padding-right: 10px;
  }
`;

const Verify = styled.div`
  max-width: 100%;
  margin: auto;
  clear: both;

  & p {
    font-size: 16px;
    line-height: 1.5em;
  }
`;

const ValidateForm = styled.form`
  padding: 0;
  clear: both;

  & p {
    color: #999;
  }
`;

const EmptyFields = styled.div`
  padding-top: 20px;
  font-weight: 400;
  color: #e74c3c;
  font-size: 14px;
  display: none;
`;

const FieldSet = styled.p`
  font-size: 0;
  position: relative;
  margin: 20px 0;

  & span {
    display: none;
    text-align: left;
    color: #e74c3c;
    font-size: 14px;
    line-height: 1;
    margin-bottom: 10px;
  }

  & input[type="text"] {
    color: #fff;
    background: #2d2d2d;
    border: 1px solid #2d2d2d;
    outline: none;
  }

  & input::placeholder {
    color: #fff;
    opacity: 1;
  }
`;
const Icon = styled.label`
  position: absolute;
  bottom: 18px;
  left: 20px;
  margin-bottom: -7px;
  color: #eee;
  font-size: 14px;
`;

const FullWidthInput = styled.input`
  width: 100%;
  box-sizing: border-box;
  padding: 15px 15px 15px 15px;
  border-radius: 3px;
`;

const EmailLabel = styled.label`
  position: relative;
  display: block;
  line-height: 1;
  text-align: left;
  top: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding-bottom: 10px;
  color: #eee;
  font-size: 14px;
`;

const MonthInput = styled.input`
  width: calc(50% - 5px);
  margin-right: 5px;
  padding: 12px 20px;
  display: inline-block;
  box-sizing: border-box;
  border-radius: 3px;
`;

const YearInput = styled.input`
  width: calc(50% - 5px);
  margin-left: 5px;
  padding: 12px 20px;
  display: inline-block;
  margin-right: 0;
  box-sizing: border-box;
  border-radius: 3px;
`;

const Packages = styled.div`
  position: relative;
  padding: 0;
`;

const PackagesList = styled.ul`
  width: 100%;
  margin: auto;
  padding-top: 10px;
  list-style: none;

  & li {
    display: block;
    width: 100%;
    margin: auto;
    font-size: 14px;
  }

  & li input[type="radio"] {
    display: none;
  }

  & li input[type="radio"] + label {
    background: #2d2d2d;
  }

  & li input[type="radio"].active + label {
    background: #298eea;
  }

  & li label {
    position: relative;
    display: block;
    width: 100%;
    color: #fff;
    padding: 0;
    border: none;
    outline: none;
    transition: 0.3s ease;
    cursor: pointer;
    background: #298eea;
  }

  & li label:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: -6px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transition: 0.3s ease;
    background: #fff;
  }

  & li label:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: -3px;
    margin-left: 3px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    transition: 0.3s ease;
    background: #212121;
    display: none;
  }

  & li.active label:after {
    display: block;
  }
`;

const ValidateSubmit = styled.p`
  position: relative;
  margin: 20px 0;

  & input[type="submit"] {
    padding-left: 50px;

    display: block;
    width: 100%;
    margin: 0;

    text-align: center;
    padding: 16px 0;
    cursor: pointer;
    background: #298eea;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 3px;
    -webkit-appearance: none;
  }

  & i {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    line-height: 49px;
    padding: 0 17px;
    color: #fff;
    background: rgba(0, 0, 0, 0.1);
  }
`;

const products = [7.99, 14.99, 20.99];
const productLabels = [
  "30 days subscription",
  "60 days subscription",
  "90 days subscription",
];

const isValid = (form) => {
  if (
    !form.first_name ||
    !form.last_name ||
    !form.address ||
    !form.city ||
    !form.zip ||
    !form.state ||
    !form.country ||
    !form.phone_no
  )
    return false;
  return true;
};

const Premium = (props) => {
  const user = useSelector((redux) => redux.user);
  const [product, setProduct] = useState(0);
  const [discountCode, setDiscountCode] = useState();
  const [validDiscount, setValidDiscount] = useState(1);
  const [form, setForm] = useState({});
  const dispatch = useDispatch();

  const closedFunction = () => console.log("closed");
  const successFunction = (transactionId, cid, us, prod) => {
    const transaction = {
      transactionId,
      email: us.username,
      product: prod,
      cid,
      amount: products[prod] * validDiscount,
    };
    axios
      .post("/api/transaction", transaction)
      .then((res) => {
        Swal.fire("Payment Received!", "Thanks for the payment", "success");
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire(
          "Error",
          "There was an error with your payment please contact us, providing transaction id: " +
            transactionId,
          "error"
        );
      });
  };

  useEffect(() => {
    const cancelRightClick = (event) => event.preventDefault();
    document.addEventListener("contextmenu", cancelRightClick);
    const myInt = setInterval(async () => {
      const user = await dispatch(checkUser());
    }, 1500);
    return () => {
      document.removeEventListener("contextmenu", cancelRightClick);
      clearInterval(myInt);
    };
  }, []);

  return (
    <MainWrapper>
      <SectionWrapper>
        <Header>Premium</Header>
        <SubHeader>Access to unlimited movies and TV shows!</SubHeader>
        <Content>
          <Features>
            <h3>A premium viewing experience</h3>
            <p>
              Start enjoying <strong>thousands of movies and TV shows</strong>.
              With an ever growing movie and TV show database{" "}
              <strong>you'll never run out of things to watch</strong>.
            </p>
            <p>
              We offer the{" "}
              <strong>most, highest quality and latest content</strong> that's
              available on the market. Watch your favorite movies online before
              they're in theaters!
            </p>
            <p>
              <strong>Choose a package that's right for you:</strong>
            </p>
            <MoreFeatures>
              <li>
                <PackageTime>30 Days</PackageTime>
                <PackagePrice>$ 7.99</PackagePrice>
              </li>
              <li>
                <PackageTime>60 Days</PackageTime>
                <PackagePrice>$ 14.99 (Save 7%)</PackagePrice>
              </li>
              <li>
                <PackageTime>90 Days</PackageTime>
                <PackagePrice>$ 20.99 (Save 13%)</PackagePrice>
              </li>
            </MoreFeatures>
            <p>
              Missing content? Send us a message and we can most likely add it.
              We listen to our customers and are always happy to help!
            </p>
            <p>
              <strong>
                Nouwall provides a safe and secure payment environment. Enjoy!
              </strong>
            </p>
          </Features>
          <Premiumform>
            <h3>Get premium</h3>
            <SecureServer>
              <i className="fas fa-lock fa-lg" />
              Secure Server
            </SecureServer>
            <Verify>
              <p>
                This charge will appear in your bank statement as:{" "}
                <strong>NOU WALL-DAIR</strong>. Please Do NOT Dispute. If you
                would like to cancel your Premium membership or change your
                Premium account plan details, please just contact us via email
                and we will take care of it right away
              </p>
              <ValidateForm id="payform" onsubmit="return false;" method="POST">
                <Packages style={{ marginTop: "20px", marginBottom: "-75px" }}>
                  <p>Select a package</p>
                  <PackagesList>
                    <li className={product === 0 ? "active" : ""}>
                      <input
                        id="trial1"
                        className={product === 0 ? "active" : ""}
                        onClick={() => setProduct(0)}
                        type="radio"
                        name="trial"
                        value="30"
                        checked=""
                      />
                      <label htmlFor="trial1">
                        <div
                          style={{
                            display: "inline-block",
                            width: "115px",
                            fontSize: "16px",
                            color: "#fff",
                            lineHeight: "40px",
                            padding: "2px 0px 0px 42px",
                          }}
                        >
                          30 Days
                        </div>
                        <div
                          style={{
                            display: "inline-block",
                            position: "absolute",
                            right: 0,
                            textAlign: "right",
                            color: "#fff",
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "40px",
                            padding: "2px 15px 0px 0px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: 300,
                              paddingRight: "5px",
                              color: "rgba(255,255,255,.7)",
                            }}
                          >
                            $
                          </span>
                          <span>7.99</span>
                        </div>
                      </label>
                    </li>
                    <li className={product === 1 ? "active" : ""}>
                      <input
                        id="trial2"
                        className={product === 1 ? "active" : ""}
                        onClick={() => setProduct(1)}
                        type="radio"
                        name="trial"
                        value="60"
                      />
                      <label htmlFor="trial2">
                        <div
                          style={{
                            display: "inline-block",
                            width: "115px",
                            fontSize: "16px",
                            color: "#fff",
                            lineHeight: "40px",
                            padding: "2px 0px 0px 42px",
                          }}
                        >
                          60 Days
                        </div>
                        <div
                          style={{
                            display: "inline-block",
                            position: "absolute",
                            right: 0,
                            textAlign: "right",
                            color: "#fff",
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "40px",
                            padding: "2px 15px 0px 0px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: 300,
                              paddingRight: "5px",
                              color: "rgba(255,255,255,.7)",
                            }}
                          >
                            $
                          </span>
                          <span>14.99</span>
                        </div>
                      </label>
                    </li>
                    <li className={product === 2 ? "active" : ""}>
                      <input
                        id="trial3"
                        className={product === 2 ? "active" : ""}
                        onClick={() => setProduct(2)}
                        type="radio"
                        name="trial"
                        value="90"
                      />
                      <label htmlFor="trial3">
                        <div
                          style={{
                            display: "inline-block",
                            width: "115px",
                            fontSize: "16px",
                            color: "#fff",
                            lineHeight: "40px",
                            padding: "2px 0px 0px 42px",
                          }}
                        >
                          90 Days
                        </div>
                        <div
                          style={{
                            display: "inline-block",
                            position: "absolute",
                            right: 0,
                            textAlign: "right",
                            color: "#fff",
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "40px",
                            padding: "2px 15px 0px 0px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: 300,
                              paddingRight: "5px",
                              color: "rgba(255,255,255,.7)",
                            }}
                          >
                            $
                          </span>
                          <span>20.99</span>
                        </div>
                      </label>
                    </li>
                  </PackagesList>
                </Packages>
                <iframe
                  id="paymentForm"
                  src={`https://www.dairesce.com/premium${
                    product === 2 ? "20" : product === 1 ? "14" : "7"
                  }99.php?cid=${user.id}`}
                  style={{ border: "0px #ffffff none" }}
                  name="myiFrame"
                  scrolling="no"
                  frameborder="1"
                  marginheight="0px"
                  marginwidth="0px"
                  height="1200px"
                  width="550px"
                  allowfullscreen
                  sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts"
                ></iframe>
              </ValidateForm>
            </Verify>
          </Premiumform>
        </Content>
      </SectionWrapper>
    </MainWrapper>
  );
};

export default Premium;
