import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import axios from 'axios';
import { API_URL } from '../config';

const MainWrapper = styled.div`
position: relative;
color:white;
display: block;
vertical-align: top;
background: #1b1b1b;
width: calc(100% - 100px);
min-height: 100%;
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
margin: 70px 0 0 100px;
height:100vh;
z-index: 1;
`;

const SectionWrapper = styled.section`
padding: 30px;
max-width: 1200px;
margin: auto;
`;

const Header = styled.h1`
font-size: 40px;
font-weight: 300;
line-height: 1.5em;
`;

const SubHeader = styled.h3`
font-size: 24px;
font-weight: 300;
margin-bottom: 30px;
line-height: 1.5em;
`;

const Content = styled.div`
display: table;
@media screen and (max-width: 770px) {
display: block;
}
width: 100%;
margin-top: 30px;
`;

const Features = styled.div`
background: #2d2d2d;
width: 100%;
@media screen and (max-width: 770px) {
  width: 100%;
  display:block;
}
position: relative;
display: table-cell;
padding: 30px;
vertical-align: top;
text-align:left;

& h3{
  padding-bottom: 10px;
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 24px;
}

& p{
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5em;
  margin-bottom: 30px;
  padding-left: 20px;
  padding-right: 10%;
}

& p + p {
  padding-top: 15px;
}

& ul{

}
`;

const FAQ = (props) => {
  const [FAQ, setFAQ] = useState([]);
  const getFAQs = () => {
    axios.get(`${API_URL}faqs?_sort=createdAt:DESC`)
    .then(res => {
      setFAQ(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    getFAQs();
  }, [])

  return (
    <MainWrapper>
      <SectionWrapper>
        <Header>FAQ</Header>
        <SubHeader>Frequently Asked Questions</SubHeader>
        <Content>
          <Features>
            {FAQ.map(item => (
            <>
              <h3>{item.question}</h3>
              <p>
              {item.answer}
              </p>
            </>
            ))}

          </Features>
        </Content>
      </SectionWrapper>
    </MainWrapper>
  );
};

export default FAQ;
