import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import saveReferrals from '../redux/actions/referrals.actions';

const MainWrapper = styled.div`
position: relative;
color:white;
display: block;
vertical-align: top;
background: #1b1b1b;
width: calc(100% - 100px);
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
margin: 70px 0 0 100px;
height:100%;
min-height: calc(100vh - 70px);
z-index: 1;
@media screen and (max-width: 770px) {
  width: 100%;
}
`;

const InnerContainer = styled.section`
  padding: 0 30px;
  margin: auto;
  padding-bottom: 30px;
  width: 80%;
  max-width: 1300px;
  position: relative;
  padding-top: 30px;
  text-align: center;

  @media screen and (max-width: 770px) {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }

  & h1 {
    padding: 60px 0 20px 0;
    font-size: 40px;
    font-weight: 300;
    line-height: 1.5em;
  }

  &.profile > i {
    font-size: 64px;
    color: #fff;
    position: relative;
    background: #31ad20;
    display: block;
    width: 80px;
    height: 71px;
    margin: 30px auto;
    padding: 14px 9px 11px;
    text-align: center;
    border-radius: 50%;
    border: 15px solid #141414;
    box-sizing: content-box;
    overflow: hidden;
}
`;

const AccountTabs = styled.ul`
  border-bottom: 1px solid rgba(255,255,255,.1); 
  list-style: none;

  & li {
    display: inline-block;
    font-size: 15px;
    margin-bottom: -2px;
  }

  & li a {
    display: inline-block;
    color: rgba(255,255,255,.7);
    font-weight: 600;
    padding: 0 20px;
    border-bottom: 1px solid transparent;
    text-decoration: none;
    transition: .3s ease;
  }

  & li a:hover span {
    border-color: rgba(255,255,255,0.2);
  }

  & li a span {
      display: inline-block;
      padding: 15px 0px;
      border-bottom: 1px solid transparent;
  }

  & li.active a span {
    color: #fff;
    border-color: #fff;
  }
`;

const UserInfo = styled.div`
  display: inline-block;
  padding: 80px 80px 60px 80px;
  @media screen and (max-width: 770px) {
    padding: 20px 10px 5px 10px;
    width: 100%;
  }
  background: rgba(255, 255, 255, 0.08);
  border-radius: 3px;
  margin-top: -65px;

  & .form {
    padding: 20px;
  }

  @media screen and (max-width: 770px) {
    & .form {
      padding-left: 0;
      padding-right: 0;
    }
  }

  & .form .fieldset:first-child {
    margin-top: 0;
  }

  & .form .fieldset {
    position: relative;
    margin: 20px 0;
  }

  & h4 {
    line-height: 1.5em;
  }

  & .form input.has-padding {
    padding: 12px 20px 12px 19px;
  }

  & .form input.has-border {
    border: 1px solid #d2d8d800;
    color: #fff;
    background-color: #ffffff21;
  }

  & .form input {
    margin: 0;
    border-radius: 3px;
    font-size: 16px;
    max-width: 500px;
    flex-grow: 1;
  }

  & p {
    font-size: 16px;
    line-height: 1.5em;
  }

  & .account-status {
    color: #298eea;
    font-weight: 700;
  }

  & .premiumtime {
    color: #298eea;
    font-weight: 700;
 }
 & button {
   border-radius: 3px;
   padding: 10px;
   border:none;
   font-size: 18px;
   text-align: center;
   background:#31ad20;
   color: white;
  cursor:pointer;
 }

 & i {
  margin-right: 10px;
  font-size: 16px;
}
 `;

const Offers = styled.div`
margin: 40px 0px;

& h4 {
  font-size: 22px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 0px!important;
}

& ul {
  text-align: left;
  margin-left: 15px;
  padding-top: 15px;
}
`;

const InviteList = styled.div`
text-align: left;

& h4 {
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 15px;
}

& span {
  background-color: #00000026;
  padding: 4px 10px;
  border-radius: 8px;
  margin-left: 5px;
}
`;

const sorting = (a,b) => {
  return b.status.localeCompare(a.status)
};

const Invite = (props) => {
  const user = useSelector(redux => redux.user);
  const referrals = useSelector(redux => redux.referrals)
  const dispatch= useDispatch();

  useEffect(() => {
    axios.get('/api/referrals?id='+user.id)
    .then(res => {dispatch(saveReferrals(res.data)); });
  },[user])

  const onCopy = (e) => {
    e.preventDefault();
    var copyTextarea = document.querySelector('#referral');
    copyTextarea.focus();
    copyTextarea.select();
  
    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
    } catch (err) {
      console.log('Oops, unable to copy');
    }
  }

  return (
  <MainWrapper>
    <InnerContainer className="profile">
      <AccountTabs>
        <li><Link to="/user"><span>Settings</span></Link></li>
        <li className="active"><Link to="/user/invite"><span>Referrals</span></Link></li>
        <li><Link to="/user/watchlist"><span>Watchlist</span></Link></li>
        <li><Link to="/user/favorites"><span>Favorites</span></Link></li>
      </AccountTabs>
      <h1>Referrals</h1>
      <i className="fas fa-link" />
      <UserInfo>
        <form className="form">
          <p
            className="fieldset header-text"
            style={{
              fontSize: '17px',
              marginBottom: '40px',
              lineHeight: '30px',
            }}
          >
Refer your friends for a chance to become a premium member. For every paid user you invite, you receive 7 days of {' '}<strong>PREMIUM</strong>{' '} access!
          </p>
          <p className="fieldset" />
          <h4
            className="fieldset"
            style={{
              fontSize: '22px',
              textAlign: 'left',
            }}
          >
            <strong>Your Referral Link</strong>
          </h4>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <input id="referral" className="full-width has-padding has-border" name="link" type="text" value={`https://nouwall.to/register?ref=${user.id}`} placeholder="your friend email" />
          <button onClick={onCopy}><i className="fas fa-link" />Copy</button>
          </div>
          <p />
          <Offers>
            <h4 className="fieldset">
              Referral List
            </h4>
<ReferralTable>
  {(referrals && referrals.length > 0) && (
<>
  <Row style={{backgroundColor: '#31ad20', color: 'white', borderTopRightRadius: '4px', borderTopLeftRadius: '4px', fontWeight: 700}}>
  <div>#</div>
  <div>Name</div>
  <div>Bonus days</div>
  <div>Status</div>
  </Row>
  { referrals.sort(sorting).map((item, index) => (
  <Row>
    <div>{index+1}</div>
    <div>{item.referredName}</div>
    <div>+ 7 days</div>
    {item.status === 'Pending' && (
    <div style={{color: '#f1b739'}}><i className="fas fa-hourglass-half"></i>{item.status}</div>
    )}
    {item.status !== 'Pending' && (
            <div style={{color: '#31ad20'}}><i className="fas fa-check-circle"></i>{item.status}</div>
    )}
  </Row>
    ))}
    </>
  )}
  {(!referrals || referrals.length === 0) && (
    <>No referrals</>
  )}
</ReferralTable>
          </Offers>
        </form>
      </UserInfo>
    </InnerContainer>
  </MainWrapper>

);
          };

export default Invite;

const ReferralTable = styled.div`
  width: 100%;
  text-align:left;
`;
const Row = styled.div`
padding: 10px;
display:flex;
justify-content:space-between;

&:nth-child(even) {
  background-color: #ffffff21;
}

&:nth-child(odd) {
  background-color: #4b454533;
}

& div:nth-child(1) {
  width: 50px;
}
& div:nth-child(2) {
  width: 150px;
  flex-grow:1;
}
& div:nth-child(3) {
  width: 100px;
}
& div:nth-child(4) {
  width: 140px;
}
`;