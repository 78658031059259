import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getFrontPage } from "../redux/actions/frontPage.actions";

const SidebarWrapper = styled.div`
  @media screen and (max-width: 770px) {
    display: none;
  }
  position: fixed;
  background: rgb(39, 39, 39) !important;
  width: 100px;
  height: inherit;
  top: 70px;
  pointer-events: all;
  color: white;
  z-index: 20;
`;

const NavElement = styled.ul`
  list-style: none;

  & li {
    display: block;
    font-size: 14px;
    @media screen and (max-width: 760px) {
      float: left;
    }
  }

  & li a {
    display: block;
    color: rgba(255, 255, 255, 0.7);
    padding: 21px 15px;
    text-decoration: none;
  }

  & li a:hover {
    color: #fff;
    background: rgba(0, 0, 0, 0.2);
  }

  & li.active a {
    color: #fff;
    background: rgb(41, 142, 234);
  }

  & li a i {
    display: block;
    text-align: center;
    font-size: 24px;
    padding-bottom: 10px;
  }

  & li a span {
    display: block;
    text-align: center;
  }
`;

const Sidebar = (props) => {
  const dispatch = useDispatch();
  const getMovies = () => {
    dispatch(getFrontPage());
  };

  useEffect(() => {
    getMovies();
  }, []);

  const location = useLocation();

  if (
    location.pathname === "/login" ||
    location.pathname === "/register" ||
    location.pathname === "/forgot-password" ||
    location.pathname.split("/")[1] === "forgot-password"
  )
    return null;

  return (
    <SidebarWrapper>
      <NavElement>
        <li className={`${location.pathname === "/" ? "active" : ""}`}>
          <Link to="/">
            <i className="fas fa-home" />
            <span>Home</span>
          </Link>
        </li>
        <li className={`${location.pathname === "/movies" ? "active" : ""}`}>
          <Link to="/movies">
            <i className="fas fa-film" />
            <span>Movies</span>
          </Link>
        </li>
        <li className={`${location.pathname === "/series" ? "active" : ""}`}>
          <Link to="/series">
            <i className="fas fa-tv" />
            <span>TV Series</span>
          </Link>
        </li>
        <li
          className={`${location.pathname === "/new-episodes" ? "active" : ""}`}
        >
          <Link to="/new-episodes">
            <i className="fas fa-tv" />
            <span>New Episodes</span>
          </Link>
        </li>
        <li className={`${location.pathname === "/premium" ? "active" : ""}`}>
          <Link to="/premium">
            <i className="fas fa-star fa-3x" />
            <span>Premium</span>
          </Link>
        </li>
        <li className={`${location.pathname === "/faq" ? "active" : ""}`}>
          <Link to="/faq">
            <i className="fas fa-question-circle fa-3x" />
            <span>FAQ</span>
          </Link>
        </li>
        <li className={`${location.pathname === "/contact" ? "active" : ""}`}>
          <Link to="/contact">
            <i className="fas fa-envelope fa-3x" />
            <span>Contact</span>
          </Link>
        </li>
      </NavElement>
    </SidebarWrapper>
  );
};

export default Sidebar;
