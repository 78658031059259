import React, { useEffect, useState, useRef, createRef } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "@emotion/styled";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import {
  Player,
  ControlBar,
  ReplayControl,
  ForwardControl,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  VolumeMenuButton,
} from "video-react";
import "video-react/dist/video-react.css";
import axios from "axios";

import { convert } from "../helper";
import { API_URL } from "../config";
import ClosedCaptionButton from "video-react/lib/components/control-bar/ClosedCaptionButton";
import { updateLastWatch } from "../redux/actions/user.actions";
import {
  startDownload,
  finishDownload,
} from "../redux/actions/downloads.actions";
import NewsWrapper from "../components/NewsWrapper";
import SimilarMovies from "../components/SimilarMovies";
import image from "../img/black_bg.jpg";
import WatchButton from "./watchbutton";
import ShowPremiumWarning from "../components/ShowPremiumWarning";
import DownloadButton from "./DownloadButton";

const MainWrapper = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url(${(props) => `https://image.tmdb.org/t/p/w780/${props.bgImage}`})
      no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  display: block;
  vertical-align: top;
  width: 100%;
  min-height: 100%;

  @media only screen and (max-width: 600px) {
    .video-react .video-react-icon-forward-10,
    .video-react .video-react-icon-replay-10 {
      display: none;
    }
    .video-react .video-react-control {
      width: 2em;
    }
  }
  @media only screen and (max-width: 450px) {
    .video-react-time-control {
      display: none;
    }
  }
`;

const SectionWrapper = styled.section`
  margin-top: 70px;
  margin: auto;
  padding-bottom: 30px;
  width: 80%;
  @media screen and (max-width: 770px) {
    width: 100%;
  }
  max-width: 1300px;
  position: relative;
  padding-top: 30px;
`;

const Video = styled.div`
  position: relative;
  display: block;
  width: 100%;
  margin: 40px auto 0 auto;
  padding-right: 0px;
  vertical-align: top;
  z-index: 1;
  background-color: #000000;

  &.fullScreen {
    position: fixed;
    z-index: 1000;
    top: 0;
    margin-top: 0;
    left: 0;
    bottom: 0;
  }

  .video-react-big-play-button {
    display: none;
  }

  .video-react {
    height: 100%;
  }

  .video-react .video-react-video {
    position: static !important;
  }

  & img {
    width: 100%;
    z-index: 100;
    display: block;
    opacity: 0.55;
  }

  & i.fa-play {
    position: absolute;
    font-size: 54px;
    color: #d0d2d4;
    z-index: 190;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
  }
  .fa-play:hover {
    color: white;
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  max-width: 100%;
  margin: auto;
  padding: 30px;
  color: #fff;
  background: rgb(19, 19, 19) !important;
  box-shadow: 0px 16px 13px -10px rgba(0, 0, 0, 0.8);
`;

const MovieInfo = styled.div`
  width: 75%;
  @media screen and (max-width: 770px) {
    width: 100%;
    margin-bottom: 20px;
  }
  display: inline-block;
  vertical-align: top;
  padding: 0;
  position: relative;
  z-index: 10;
  text-align: left;

  & h1 {
    font-size: 36px;
    line-height: 1;
    padding-bottom: 15px;
    font-weight: 300;
  }

  & p {
    color: rgba(255, 255, 255, 0.71);
    font-size: 16px;
    line-height: 1.5em;
    padding-top: 20px;
  }
`;

const MovieData = styled.div`
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
  padding-bottom: 20px;

  & .rating {
    position: relative;
    display: inline-block;
    padding-bottom: 5px;
  }

  & .rating > i {
    padding: 0;
    margin: 0 2px 0 0;
  }

  & span:first-child:before {
    visibility: hidden;
    content: "";
    position: absolute;
    background: transparent;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    bottom: 23px;
    left: 50%;
    margin-left: -7px;
    border-top: 6px solid #2f3444;
    margin-top: 6px;
    z-index: 15;
  }

  & span:before {
    content: "";
    display: inline-block;
    width: 2px;
    height: 2px;
    margin: 0 10px;
    background: rgba(255, 255, 255, 0.3);
    vertical-align: middle;
  }

  & span i {
    padding-right: 8px;
  }
`;

const Select = styled.select`
  position: relative;
  color: rgba(255, 255, 255, 0.7);
  background: #414141;
  height: 30px;
  padding: 0 30px 0 15px;
  border: none;
  outline: none;
  border-radius: 3px;
`;

const MovieActions = styled.div`
  position: relative;
  z-index: 10;
  display: inline-block;
  width: 25%;
  @media screen and (max-width: 770px) {
    width: 100%;
  }
  vertical-align: top;

  & ul {
    list-style: none;
  }

  & a {
    text-decoration: none;
    transition: 0.3s ease;
  }

  & ul:first-child {
    margin-bottom: 30px;
  }

  & .extra {
    margin: auto;
  }

  & ul > li {
    position: relative;
    font-size: 16px;
    display: block;
    color: rgba(255, 255, 255, 0.7);
    background: rgb(33, 33, 33);
    text-align: center;
  }

  & ul li i {
    vertical-align: text-top;
    padding-right: 10px;
  }

  & ul > li a {
    color: rgba(255, 255, 255, 0.7);
    display: block;
  }

  & ul > li a:hover {
    color: #fff;
  }

  & .extra li {
    text-align: left;
    display: block;
    position: relative;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.7);
    background: rgb(33, 33, 33);
  }

  & .extra li div {
    padding: 16px 20px 16px 68px;
    cursor: pointer;
  }

  & .extra li div:hover {
    color: #fff;
    background: rgba(0, 0, 0, 0.1);
  }

  & .extra li .download {
    padding: 16px 20px 16px 68px;
  }

  & .extra li:first-child {
    border-radius: 5px 5px 0 0;
    background-color: #212121;
  }

  & .extra li:last-child {
    border-radius: 0 0 5px 5px;
    background-color: #212121;
  }

  & .extra li div i {
    position: absolute;
    top: 0;
    left: 0;
    padding: 16px;
    background: rgba(0, 0, 0, 0.1);
    height: 53px;
  }

  & li i {
    vertical-align: text-top;
  }

  & #subtitle-button label {
    display: block;
    padding: 16px 20px;
    cursor: pointer;
  }

  & ul li#subtitles {
    border-radius: 5px 5px 0 0;
  }

  & ul > li:nth-child(even) {
    background-color: #212121;
    border-top: 0px;
    border-bottom: 0px;
  }

  & .play {
    position: relative;
    font-weight: 700;
    color: #fff;
    background: #298eea;
    padding: 18px 25px 18px 25px;
    border-radius: 5px;
    margin-top: 5px;
    cursor: pointer;
  }

  & #quality-button {
    display: none !important;
  }
`;

const bringLinks = (movie, server) => {
  const {
    s1Video720,
    s1Video1080,
    s2Video1080,
    s2Video720,
    s3Video720,
    s3Video1080,
    s4Video1080,
    s4Video720,
  } = movie;
  const links = {};
  if (server === "s1") {
    if (s1Video1080) links["hd"] = true;
    if (s1Video720) links["sd"] = true;
  }
  if (server === "s2") {
    if (s2Video1080) links["hd"] = true;
    if (s2Video720) links["sd"] = true;
  }
  if (server === "s3") {
    if (s3Video1080) links["hd"] = true;
    if (s3Video720) links["sd"] = true;
  }
  if (server === "s4") {
    if (s4Video1080) links["hd"] = true;
    if (s4Video720) links["sd"] = true;
  }
  return links;
};

const QualityChanger = ({ setActiveQuality, movie, server, activeQuality }) => {
  const [showQualities, setShowQualities] = useState();
  const [links, setLinks] = useState({});
  useEffect(() => {
    setLinks(bringLinks(movie, server));
  }, [movie, server]);
  if (!links.hd || !links.sd) return null;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
    >
      {showQualities && (
        <div
          style={{
            position: "absolute",
            borderRadius: "4px",
            padding: "10px",
            maxWidth: "80px",
            top: "0",
            transform: "translate(-50%,-110%)",
            backgroundColor: "rgb(39,39,39)",
          }}
        >
          <span
            style={
              activeQuality !== 1080 && activeQuality
                ? { cursor: "pointer", color: "rgba(255,255,255,0.5" }
                : { cursor: "pointer" }
            }
            onClick={() => {
              setActiveQuality(1080);
            }}
          >
            1080p
          </span>
          <br />
          <br />
          <span
            style={
              activeQuality !== 720
                ? { cursor: "pointer", color: "rgba(255,255,255,0.5" }
                : { cursor: "pointer" }
            }
            onClick={() => {
              setActiveQuality(720);
            }}
          >
            720p
          </span>
        </div>
      )}
      <button onClick={() => setShowQualities(!showQualities)}>
        <i className="fas fa-cog" style={{ fontSize: "16px" }} />
      </button>
    </div>
  );
};

const addToFavorites = (data) => {
  const tmp = localStorage.getItem("favorites")
    ? JSON.parse(localStorage.getItem("favorites"))
    : {};
  tmp[data.id] = {
    url:
      "/movie/" +
      data.id +
      "-" +
      encodeURI(data.title.split(" ").join("-").split("/").join("%2F")),
    img: data.poster,
  };
  localStorage.setItem("favorites", JSON.stringify(tmp));
  Swal.fire("Success", "Movie has been added to your favorites", "success");
};

const addToWatchList = (data) => {
  const tmp = localStorage.getItem("watchlist")
    ? JSON.parse(localStorage.getItem("watchlist"))
    : {};
  tmp[data.id] = {
    url:
      "/movie/" +
      data.id +
      "-" +
      encodeURI(data.title.split(" ").join("-").split("/").join("%2F")),
    img: data.poster,
  };
  localStorage.setItem("watchlist", JSON.stringify(tmp));
  Swal.fire("Success", "Movie has been added to your watchlist", "success");
};

const getMovie = (id) =>
  axios.get(`${API_URL}movies/?imdb_id=${id}`).then((res) => res.data);

const Movie = (props) => {
  const { cdnUrl } = useSelector((redux) => redux.statistics);
  let { id } = useParams();
  const history = useHistory();
  id = id.split("-")[0];
  const [movie, setMovie] = useState({ empty: true });
  const [link, setLink] = useState();
  const [subtitles, setSubtitles] = useState();
  const ref = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const user = useSelector((redux) => redux.user);
  const dispatch = useDispatch();
  const [downloading, setDownloading] = useState();
  const [activeQuality, setActiveQuality] = useState();

  const [server, setServer] = useState("s1");
  const [fullScreen, setFullScreen] = useState();

  const isPremium = () => {
    if (user && user.token && !user.premiumUntil) return false;
    if (user && !user.token) return undefined;
    const until = new Date(user.premiumUntil).getTime();
    const today = Date.now();
    if (until - today >= 0) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (activeQuality) handleClickToWatch();
  }, [activeQuality, server]);

  const retrieveMovie = async () => {
    const tmp = await getMovie(id);
    if (tmp.length > 0) {
      setMovie(tmp[0]);
    } else {
      history.push("/movies");
    }
  };

  useEffect(() => {
    retrieveMovie();
  }, [id]);

  useEffect(() => {}, [movie]);

  const getDownload = async (quality) => {
    const { s1Bucket, s1Account, s1Video720, s1Video1080 } = movie;

    if (quality === 720 && !s1Video720) return;
    if (quality === 1080 && !s1Video1080) return;
    let activeLink;
    if (quality === 720) {
      activeLink = await axios.get(
        `${API_URL}storage/download/${s1Video720}?server=s1`,
        {
          query: {
            s1Bucket,
            s1Account,
            server: "s1",
          },
        }
      );
    }
    if (quality === 1080) {
      activeLink = await axios.get(
        `${API_URL}storage/download/${s1Video1080}?server=s1`,
        {
          query: {
            s1Bucket,
            s1Account,
            server: "s1",
          },
        }
      );
    }
    if (activeLink && activeLink.data) {
      let url = activeLink.data;
      if (url.includes("filebase"))
        url = url.replace("s3.filebase.com", "filebase.wonulla.to");
      Swal.fire(
        "Downloading",
        "Your download has been initiated.Please don't close the current tab. We will notify you as soon as it is completed.",
        "success"
      );
      dispatch(startDownload());
      axios({
        url,
        method: "GET",
        responseType: "blob", // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "video.mp4");
          document.body.appendChild(link);
          link.click();
          dispatch(finishDownload());
        })
        .catch((error) => {
          Swal.fire("Error", "Please try again later", "error");
          dispatch(finishDownload());
        });
      //window.open(activeLink.data);
    }
  };

  const getWatchingVideo = async (movie) => {
    if (!isPremium()) return null;
    const {
      s1Bucket,
      s2Bucket,
      s1Domain,
      s2Domain,
      s1Video720,
      s1Video1080,
      s2Video1080,
      s2Video720,
      s3Bucket,
      s4Bucket,
      s3Domain,
      s4Domain,
      s3Video720,
      s3Video1080,
      s4Video1080,
      s4Video720,
    } = movie;
    const video = getVideoToWatch();
    let activeLink;
    if (video === "s1Video720") {
      activeLink = `${movie.s1Domain}/${movie.s1Bucket}/${s1Video720}`;
      setServer("s1");
    }
    if (video === "s2Video720") {
      activeLink = `${movie.s2Domain}/${movie.s2Bucket}/${s2Video720}`;
      setServer("s2");
    }
    if (video === "s3Video720") {
      activeLink = `${movie.s3Domain}/${movie.s3Bucket}/${s3Video720}`;
      setServer("s3");
    }
    if (video === "s4Video720") {
      activeLink = `${movie.s4Domain}/${movie.s4Bucket}/${s4Video720}`;
      setServer("s4");
    }
    if (video === "s1Video1080") {
      activeLink = `${movie.s1Domain}/${movie.s1Bucket}/${s1Video1080}`;
      setServer("s1");
    }

    if (video === "s2Video1080") {
      activeLink = `${movie.s2Domain}/${movie.s2Bucket}/${s2Video1080}`;
      setServer("s2");
    }

    if (video === "s3Video1080") {
      activeLink = `${movie.s3Domain}/${movie.s3Bucket}/${s3Video1080}`;
      setServer("s3");
    }

    if (video === "s4Video1080") {
      activeLink = `${movie.s4Domain}/${movie.s4Bucket}/${s4Video1080}`;
      setServer("s4");
    }
    if (activeLink) {
      if (cdnUrl)
        activeLink = activeLink.replace("s3.wasabisys.com/s1account", cdnUrl);
      setLink(activeLink);
      var iOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      function detectMob() {
        const toMatch = [
          /Android/i,
          /webOS/i,
          /iPhone/i,
          /iPad/i,
          /iPod/i,
          /BlackBerry/i,
          /Windows Phone/i,
        ];

        return toMatch.some((toMatchItem) => {
          return navigator.userAgent.match(toMatchItem);
        });
      }
      if (iOS || detectMob()) {
        ref2.current.toggleFullscreen();
        setFullScreen(true);
      }
    }
  };

  const handleClickToWatch = () => {
    if (movie && !movie.empty) {
      getWatchingVideo(movie);
    }
  };
  const VideoPlayer = styled(Player)`
    padding-top: 0 !important;
    font-family: Roboto;
    font-size: 13px;
    position: absolute;
    top: 0;
  `;

  useEffect(() => {
    if (ref2 && ref2.current) {
      if (!ref2.current.getState().player.isFullscreen && fullScreen) {
        console.log(33333);
        setFullScreen(false);
      }
    }
  });

  useEffect(() => {
    if (link) {
      getWatchingVideo(movie);
    }
  }, [server]);

  const getAvailableLinks = () => {
    const {
      s1Video720,
      s1Video1080,
      s2Video1080,
      s2Video720,
      s3Video720,
      s3Video1080,
      s4Video1080,
      s4Video720,
    } = movie;
    const tmp = {};

    if (s1Video1080) tmp.s1 = { s1Video1080: true };
    if (s1Video720) tmp.s1 = { s1Video720: true };
    if (s2Video1080) tmp.s2 = { s2Video1080: true };
    if (s2Video720) tmp.s2 = { s2Video720: true };
    if (s3Video1080) tmp.s3 = { s3Video1080: true };
    if (s3Video720) tmp.s3 = { s3Video720: true };
    if (s4Video1080) tmp.s4 = { s4Video1080: true };
    if (s4Video720) tmp.s4 = { s4Video720: true };
    return tmp;
  };

  const handleUploadSubtitles = (event) => {
    var selectedFile = event.target.files[0];
    if (!selectedFile) return;
    const reader = new FileReader();
    const reader2 = new FileReader();
    let t = "";
    reader.onload = (e) => {
      const webvtt = convert(e.target.result);
      const formed = webvtt;
      t = new Blob([webvtt], {
        type: "text/plain",
      });
      reader2.readAsDataURL(t);
    };

    reader2.onload = (e) => {
      setSubtitles(e.target.result);
    };

    reader.readAsText(event.target.files[0]);
  };

  const getVideoToWatch = () => {
    const {
      s1Video720,
      s1Video1080,
      s2Video1080,
      s2Video720,
      s3Video720,
      s3Video1080,
      s4Video1080,
      s4Video720,
    } = movie;
    if (server === "s1") {
      if (s1Video1080 && (!activeQuality || activeQuality === 1080))
        return "s1Video1080";
      if (s1Video720) return "s1Video720";
      if (s2Video1080 && (!activeQuality || activeQuality === 1080))
        return "s2Video1080";
      if (s2Video720) return "s2Video720";
      if (s3Video1080 && (!activeQuality || activeQuality === 1080))
        return "s3Video1080";
      if (s3Video720) return "s3Video720";
      if (s3Video1080 && (!activeQuality || activeQuality === 1080))
        return "s3Video1080";
      if (s3Video720) return "s3Video720";
      if (s4Video1080 && (!activeQuality || activeQuality === 1080))
        return "s4Video1080";
      if (s4Video720) return "s4Video720";
    }
    if (server === "s2") {
      if (s2Video1080 && (!activeQuality || activeQuality === 1080))
        return "s2Video1080";
      if (s2Video720) return "s2Video720";
      if (s1Video1080 && (!activeQuality || activeQuality === 1080))
        return "s1Video1080";
      if (s1Video720) return "s1Video720";
      if (s3Video720) return "s3Video720";
      if (s3Video1080 && (!activeQuality || activeQuality === 1080))
        return "s3Video1080";
      if (s3Video720) return "s3Video720";
      if (s4Video1080 && (!activeQuality || activeQuality === 1080))
        return "s4Video1080";
      if (s4Video720) return "s4Video720";
    }
    if (server === "s3") {
      if (s3Video1080 && (!activeQuality || activeQuality === 1080))
        return "s3Video1080";
      if (s3Video720) return "s3Video720";
      if (s1Video1080 && (!activeQuality || activeQuality === 1080))
        return "s1Video1080";
      if (s1Video720) return "s1Video720";
      if (s2Video1080 && (!activeQuality || activeQuality === 1080))
        return "s2Video1080";
      if (s2Video720) return "s2Video720";
      if (s4Video1080 && (!activeQuality || activeQuality === 1080))
        return "s4Video1080";
      if (s4Video720) return "s4Video720";
    }
    if (server === "s4") {
      if (s4Video1080 && (!activeQuality || activeQuality === 1080))
        return "s4Video1080";
      if (s4Video720) return "s4Video720";
      if (s1Video1080 && (!activeQuality || activeQuality === 1080))
        return "s1Video1080";
      if (s1Video720) return "s1Video720";
      if (s2Video1080 && (!activeQuality || activeQuality === 1080))
        return "s2Video1080";
      if (s2Video720) return "s2Video720";
      if (s3Video1080 && (!activeQuality || activeQuality === 1080))
        return "s3Video1080";
      if (s3Video720) return "s3Video720";
      if (s3Video1080 && (!activeQuality || activeQuality === 1080))
        return "s3Video1080";
      if (s3Video720) return "s3Video720";
    }
  };

  const checkTime = () => {
    if (
      ref2 &&
      ref2.current &&
      ref2.current.getState() &&
      ref2.current.getState().player &&
      !ref2.current.getState().player.paused
    ) {
      dispatch(updateLastWatch());
    }
  };

  const pausePlayer = () => {
    ref2.current.pause();
  };

  useEffect(() => {
    // stop checking free watch time
    // setInterval(() => checkTime(), 2 * 60 * 1000);
  }, []);

  if (!movie || movie.empty) return null;
  return (
    <MainWrapper bgImage={movie.backdrop}>
      <Helmet>
        <title>Nouwall | {movie.title}</title>
        <link rel="canonical" href="https://nouwall.to/" />
        <meta name="description" content={movie.overview} />
      </Helmet>
      <ShowPremiumWarning pause={pausePlayer} player={ref2} />
      <SectionWrapper>
        <NewsWrapper />
        <Video className={fullScreen ? "fullScreen" : ""}>
          <img src={image} />
          <VideoPlayer
            ref={ref2}
            autoPlay
            style={{ paddingTop: 0, width: "100%" }}
            playsInline={false}
          >
            <source src={isPremium() ? link : null}></source>
            <ControlBar>
              <ReplayControl className="notMobile" seconds={10} order={1.0} />
              <ForwardControl className="notMobile" seconds={10} order={1.2} />
              <QualityChanger
                order={7.2}
                setActiveQuality={setActiveQuality}
                movie={movie}
                server={server}
                activeQuality={activeQuality}
              />
              <CurrentTimeDisplay order={4.1} />
              <TimeDivider order={4.2} />
              {subtitles && <ClosedCaptionButton order={7.1} />}
              <VolumeMenuButton order={6.2} vertical />
            </ControlBar>
            {subtitles && (
              <track
                label="English"
                kind="subtitles"
                srcLang="en"
                src={subtitles}
                default
              ></track>
            )}
          </VideoPlayer>
          {!link && (
            <div
              onClick={() => document.getElementById("watchButton").click()}
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                cursor: "pointer",
              }}
            >
              <img src={image} />
              <img
                src={`https://image.tmdb.org/t/p/w780/${movie.backdrop}`}
                style={{ position: "absolute", top: 0 }}
              />
              <i className="fas fa-play" />
            </div>
          )}
        </Video>
        <InnerContainer>
          <MovieInfo>
            <h1>{movie.title}</h1>
            <MovieData>
              <span className="rating">
                <i className="fas fa-star" /> {movie.vote_average.toFixed(1)}
              </span>
              <span className="movie-date">
                <i className="far fa-calendar" />{" "}
                {movie.release_date && movie.release_date.substring(0, 4)}
              </span>
              <span className="movie-time">
                <i className="far fa-clock" /> {movie.runtime} min
              </span>
              <span className="movie-genre">
                <i className="fas fa-mask" />{" "}
                {movie &&
                  movie.genres &&
                  movie.genres.map((genre) => genre.name).join(", ")}
              </span>
            </MovieData>
            <p
              style={{
                textAlign: "justify",
                maxHeight: "120px",
                overflow: "hidden",
                paddingRight: "20px",
              }}
            >
              {movie.overview}
            </p>
            {getAvailableLinks() &&
              Object.keys(getAvailableLinks()) &&
              Object.keys(getAvailableLinks()).length > 1 && (
                <p>
                  <b>Select server: </b>
                  <Select
                    className="select"
                    onChange={(e) => setServer(e.target.value)}
                  >
                    {Object.keys(getAvailableLinks()).map((key) => {
                      if (key === "s1") {
                        return (
                          <option value={key} selected="selected">
                            Server {key.charAt(1)}
                          </option>
                        );
                      }
                      return (
                        <option value={key}>Server {key.charAt(1)}</option>
                      );
                    })}
                  </Select>
                </p>
              )}
            <p style={{ display: "none" }}>
              <b>
                Try another video player (these links open in almost-fullscreen
                by default):
              </b>
            </p>
            <ul
              style={{
                margin: "auto",
                paddingTop: "5px",
                listStyle: "none",
                display: "none",
              }}
            >
              <li
                style={{
                  margin: "0 0 10px 0",
                  fontSize: "14px",
                }}
              >
                <a
                  style={{
                    color: "rgba(255,255,255,.7)",
                    pointerEvents: "none",
                  }}
                >
                  <span>
                    1. New video player (supports Chromecast on Chrome for
                    Mac/Windows, AirPlay on Safari)
                  </span>
                </a>{" "}
                | <span>720p</span> - <span>1080p</span>
              </li>
              <li
                style={{
                  margin: "0 0 10px 0",
                  fontSize: "14px",
                }}
              >
                <a
                  style={{
                    color: "rgba(255,255,255,.7)",
                    pointerEvents: "none",
                  }}
                >
                  <span>
                    2. Native video player (supports AirPlay and PIP on Safari)
                  </span>
                </a>{" "}
                | <span>720p</span> - <span>1080p</span>
              </li>
            </ul>
          </MovieInfo>
          <MovieActions>
            <ul className="extra">
              <li
                onClick={() =>
                  addToFavorites({
                    poster: movie.poster,
                    id,
                    title: movie.title,
                  })
                }
              >
                <div>
                  <i className="far fa-heart" />
                  <span>Add to favorites</span>
                </div>
              </li>
              <li
                onClick={() =>
                  addToWatchList({
                    poster: movie.poster,
                    id,
                    title: movie.title,
                  })
                }
              >
                <div>
                  <i className="fas fa-history" />
                  <span>Watch later</span>
                </div>
              </li>
              <DownloadButton
                id="downloadButton"
                ref2={ref3}
                getDownload={getDownload}
                serie={movie}
              />
              {/*(movie.s1Video720 && false) && (
              <li onClick={() => getDownload(720)}>
              <div className="download">                  
                  <i className="fas fa-download" />
                  <span>Download 720p</span>
              </div>
            </li>
              )}
              {(movie.s1Video1080 && false) && (
              <li onClick={() => getDownload(1080)}>
                <div className="download">
                    <i className="fas fa-download" />
                    <span>Download 1080p</span>
                </div>
              </li>
              )*/}
            </ul>
            <ul>
              <li id="subtitles" onClick={() => ref.current.click()}>
                <form
                  className="settings-button"
                  method="POST"
                  action="/movies/uploadSub"
                  encType="multipart/form-data"
                  id="subtitle-button"
                >
                  <label htmlFor="subfile">
                    <input
                      ref={ref}
                      type="file"
                      hidden
                      onChange={handleUploadSubtitles}
                    />
                    <i className="fas fa-upload" />
                    Upload subtitle .srt
                  </label>
                </form>
              </li>
              <WatchButton
                id="watchButton"
                getWatchingVideo={getWatchingVideo}
                movie={movie}
                pause={() => pausePlayer()}
              />
              <li id="quality-button">
                <div className="switch">
                  <input id="quality-switch" type="checkbox" value="" />
                  <label htmlFor="quality-switch" className="hd">
                    720p
                  </label>
                  <label htmlFor="quality-switch" className="switch-toggle" />
                  <label htmlFor="quality-switch" className="fullhd">
                    1080p
                  </label>
                </div>
              </li>
            </ul>
          </MovieActions>
          {movie && !movie.empty && <SimilarMovies movie={movie} />}
        </InnerContainer>
      </SectionWrapper>
      {downloading && (
        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            backgroundColor: "rgba(0,0,0,0.2)",
            zIndex: 10000,
          }}
        >
          <SpinnerWrapper
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              backgroundColor: "#272727",
              width: "200px",
              height: "200px",
              borderRadius: "10px",
              boxShadow: "rgba(255,255,255,0.2) 0 2px 14px 0",
            }}
          >
            <i
              className="fas fa-spinner"
              style={{
                color: "rgb(41,142,234)",
                fontSize: "80px",
                marginTop: "60px",
              }}
            />
          </SpinnerWrapper>
        </div>
      )}
    </MainWrapper>
  );
};

export default Movie;

const SpinnerWrapper = styled.div`
  & i {
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
