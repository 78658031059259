import React, { useState} from 'react';
import styled from '@emotion/styled';
import axios from 'axios';
import Swal from 'sweetalert2';

const MainWrapper = styled.div`
position: relative;
color:white;
display: block;
vertical-align: top;
background: #1b1b1b;
height:100%;
min-height: calc(100vh - 70px);
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
margin: 70px 0 0 100px;
z-index: 1;
`;

const SectionWrapper = styled.section`
padding: 30px;
`;

const Header = styled.h1`
font-size: 40px;
font-weight: 300;
line-height: 1.5em;
`;

const SubHeader = styled.h3`
font-size: 24px;
font-weight: 300;
margin-bottom: 30px;
line-height: 1.5em;
`;

const FormWrapper = styled.form`
max-width: 600px;
width: 100%;
margin: 20px auto 0 auto;
text-align: left;
padding: 20px;

& input[type=text]:focus{
  background: rgba(163, 163, 163, 0.09);
}

& input[type=text] {
  background: rgba(163, 163, 163, 0.07);
  padding: 15px 20px 15px 50px;
  border: none;
  outline: none;
  width:100%;
  color: white;
}
& input {
  border-radius: 3px;
  font-size: 16px;
}

& p {
  position:relative;
  margin-bottom: 20px;
}

& label {
  position:absolute;
  left:0;
  top:0;
  color: #666;
}

& label i {
  position: absolute;
  top: 15px;
  left: 20px;
}

& input[type=text]:focus + label, & textarea:focus + label {
  color: #fff;
}

& textarea {
  width: 100%;
  height: 200px;
  color: #fff;
  background: rgba(163, 163, 163, 0.07);
  padding: 15px 20px 15px 50px;
  border: none;
  outline: none;
  font-size: 16px;
  min-height: 50px;
  max-width: 560px;
  min-width: 200px;
}
`;

const EmailWrapper = styled.p`
& input {
  color: black !important;
  background: white !important;
}

& input[type=text]:focus + label, & textarea:focus + label {
  color: #666 !important;
}
`;

const Select = styled.select`
position: relative;
color: rgba(255,255,255,.7);
background: #414141;
height: 46px;
padding: 0 40px 0 20px;
border: none;
outline: none;
border-radius: 3px;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
font-size: 16px;
`;

const Button = styled.button`
text-align: center;
padding: 16px 25px;
cursor: pointer;
background: #298eea;
color: #FFF;
font-size: 14px;
font-weight: bold;
border: none;
border-radius: 3px;
-webkit-appearance: none;
-moz-appearance: none;
-ms-appearance: none;
-o-appearance: none;
appearance: none;
`;

const Disclaimer = styled.div`
max-width: 80%;
color: rgba(255,255,255,.7);
padding: 40px 30px;
margin: auto;
border-top: 1px solid rgba(255,255,255,.1);
text-align: left;
margin-top: 70px;
font-size: 16px;

& h4 {
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 400;
}
& p {
  margin-bottom: 20px;
}

& ul {
  list-style-type: none;
  margin-bottom: 20px;
}
& ul > li {
  text-indent: 0px;
  font-size: 14px;
}
& ul > li:before {
  content: "-";
  padding-right:10px;
}
`;

const EmailConfirmed = () => {
  return (
  <MainWrapper>
    <SectionWrapper>
      <Header>Email confirmed</Header>
      <SubHeader>Your email has been confirmed. Now you can use nouwall.</SubHeader>
    </SectionWrapper>
  </MainWrapper>
);
};

export default EmailConfirmed;
